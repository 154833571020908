import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/RootState';
import { ImpactCurveWidgetType } from 'types/Widget';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.impactCurves || initialState;

export const selectImpactCurvesData = createSelector([selectDomain], ({ data }) => data);
export const selectIsImpactCurvesLoading = createSelector(
  [selectDomain],
  ({ status }) => status === RequestStatus.LOADING,
);
export const selectImpactCurvesError = createSelector([selectDomain], ({ error }) => error);

export const selectImpactCurveDataByType = createSelector(
  [selectImpactCurvesData, (state: RootState, type: ImpactCurveWidgetType) => type],
  (impactCurvesData, widgetType) => impactCurvesData?.[widgetType],
);
export const selectIsImpactCurveDataEmpty = createSelector([selectImpactCurveDataByType], (data) => !data?.length);

export const selectImpactCurvesOptions = createSelector([selectDomain], ({ options }) => options.data);
