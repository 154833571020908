export enum TestLocators {
  Disabled = 'Disabled',
  Enabled = 'Enabled',

  Header_WTI = 'Header_WTI',
  Header_HH = 'Header_HH',
  Header_Dashboards = 'Header_Dashboards',
  Header_DataManager = 'Header_DataManager',
  Header_Help = 'Header_Help',
  Header_Help_MenuList = 'Header_Help_MenuList',
  Header_Help_MenuItem = 'Header_Help_MenuItem',
  Header_Downloads = 'Header_Downloads',
  Header_Shapefiles = 'Header_Shapefiles',
  Header_Predict_Enabled = 'Header_Predict_Enabled',
  Header_Predict_Disabled = 'Header_Predict_Disabled',
  Header_Economics_Enabled = 'Header_Economics_Enabled',
  Header_Economics_Disabled = 'Header_Economics_Disabled',
  Header_Plan_Enabled = 'Header_Plan_Enabled',
  Header_Plan_Disabled = 'Header_Plan_Disabled',
  Header_GreyedOut_SQLEditor = 'Header_GreyedOut_SQLEditor',
  Header_Jupiter = 'Header_Jupiter',

  Menu_Popper = 'Menu_Popper',

  GoTo_LandingPage = 'GoTo_LandingPage',

  UserProfile = 'UserProfile',
  UserProfile_Menu = 'UserProfile_Menu',

  Shapefile_Visible_Disabled = 'Shapefile_Visible_Disabled',
  Shapefile_Visible_Enabled = 'Shapefile_Visible_Enabled',
  Shapefile_Hidden_Disabled = 'Shapefile_Hidden_Disabled',
  Shapefile_Hidden_Enabled = 'Shapefile_Hidden_Enabled',
  Shapefiles_Menu_Closed = 'Shapefiles_Menu_Closed',

  Predict_Page_Header = 'Predict_Page_Header',
  Predict_ProdCurvesWidget_Header = 'Predict_ProdCurvesWidget_Header',
  Economics_CurveWidget_Header = 'Economics_CurveWidget_Header',
  Plan_ProdCurvesWidget_Header = 'Plan_ProdCurvesWidget_Header',

  Predict_MapWidget = 'Predict_MapWidget',
  Predict_MapWidget_Header = 'Predict_MapWidget_Header',
  Plan_MapWidget = 'Plan_MapWidget',
  Plan_MapWidget_Header = 'Plan_MapWidget_Header',

  Predict_ProdCurvesWidget = 'Predict_ProdCurvesWidget',
  Plan_ProdCurvesWidget = 'Plan_ProdCurvesWidget',
  Predict_WellInfoWidget = 'Predict_WellInfoWidget',

  Plan_Page_Header = 'Plan_Page_Header',

  Plan_Scenario_ImportBtn = 'Plan_Scenario_ImportBtn',
  Plan_Scenario_SaveBtn = 'Plan_Scenario_SaveBtn',
  Plan_Scenario_DuplicateBtn = 'Plan_Scenario_DuplicateBtn',
  Plan_Scenario_DownloadBtn = 'Plan_Scenario_DownloadBtn',
  Plan_Scenario_ShareBtn = 'Plan_Scenario_ShareBtn',

  MapWidget_Legend = 'MapWidget_Legend',
  MapWidget_Legend_Item = 'MapWidget_Legend_Item',
  MapWidget_Legend_ItemValue = 'MapWidget_Legend_ItemValue',
  MapWidget_InfoPanel_UWI = 'MapWidget_InfoPanel_UWI',
  MapWidget_InfoPanel_WellName = 'MapWidget_InfoPanel_WellName',
  MapWidget_InfoPanel_WellStatus = 'MapWidget_InfoPanel_WellStatus',
  MapWidget_Tooltip = 'MapWidget_Tooltip',
  MapWidget_PredictionLayer_Legend = 'MapWidget_PredictionLayer_Legend',
  ProdCurvesWidget_Legend_Open = 'ProdCurvesWidget_Legend_Open',
  ProdCurvesWidget_Legend_Closed = 'ProdCurvesWidget_Legend_Closed',
  ProdCurvesWidget_X_AxisLabel = 'ProdCurvesWidget_X_AxisLabel',
  ProdCurvesWidget_Y_AxisLabel = 'ProdCurvesWidget_Y_AxisLabel',
  ProdCurvesWidget_No_Planned_Wells = 'ProdCurvesWidget_No_Planned_Wells',
  ProdCurvesWidget_No_Loaded_Plan_Data = 'ProdCurvesWidget_No_Loaded_Plan_Data',
  ProdCurvesWidget_No_Data_For_Current_Filters = 'ProdCurvesWidget_No_Data_For_Current_Filters',
  WellInfoWidget_Tab = 'WellInfoWidget_Tab',
  WellInfoWidget_ItemValue = 'WellInfoWidget_ItemValue',

  ImpactCurves_Header = 'ImpactCurves_Header',
  ImpactCurveWidget_Header = 'ImpactCurveWidget_Header',
  ImpactCurveWidget_Chart = 'ImpactCurveWidget_Chart',
  EconomicsCurveWidget_Chart = 'EconomicsCurveWidget_Chart',

  WellPlanner_Well_WellsList = 'WellPlanner_Well_WellsList',
  WellPlanner_Well_WellsListItem = 'WellPlanner_Well_WellsListItem',
  WellPlanner_Well_RenameAction = 'WellPlanner_Well_RenameAction',
  WellPlanner_Well_RemoveAction = 'WellPlanner_Well_RemoveAction',
  WellPlanner_Well_VisibilityCheckbox = 'WellPlanner_Well_VisibilityCheckbox',
  WellPlanner_Well_SaveNameBtn = 'WellPlanner_Well_SaveNameBtn',
  WellPlanner_Well_CancelRenameBtn = 'WellPlanner_Well_CancelRenameBtn',

  WellPlanner_ScenariosList = 'WellPlanner_ScenariosList',
  WellPlanner_Scenario_ActionPanel = 'WellPlanner_Scenario_ActionPanel',
  WellPlanner_AddScenario_Btn = 'WellPlanner_AddScenario_Btn',
  WellPlanner_Scenario_EditBtn = 'WellPlanner_Scenario_EditBtn',
  WellPlanner_Scenario_DuplicateBtn = 'WellPlanner_Scenario_DuplicateBtn',
  WellPlanner_Scenario_DownloadBtn = 'WellPlanner_Scenario_DownloadBtn',
  WellPlanner_Scenario_ShareBtn = 'WellPlanner_Scenario_ShareBtn',
  WellPlanner_Scenario_DeleteBtn = 'WellPlanner_Scenario_DeleteBtn',
  WellPlanner_Scenario_SaveNameBtn = 'WellPlanner_Scenario_SaveNameBtn',
  WellPlanner_Scenario_CancelRenameBtn = 'WellPlanner_Scenario_CancelRenameBtn',

  WellPlanner_Scenario_Share_Dialog_Editing_Rights = 'WellPlanner_Scenario_Share_Dialog_Editing_Rights',

  Economics_Page_Header = 'Economics_Page_Header',
  Economics_ScenariosList = 'Economics_ScenariosList',
  Economics_AddScenario_Btn = 'Economics_AddScenario_Btn',
  Economics_Scenario_ActionPanel = 'Economics_Scenario_ActionPanel',
  Economics_Scenario_EditBtn = 'Economics_Scenario_EditBtn',
  Economics_Scenario_DuplicateBtn = 'Economics_Scenario_DuplicateBtn',
  Economics_Scenario_ShareBtn = 'Economics_Scenario_ShareBtn',
  Economics_Scenario_DeleteBtn = 'Economics_Scenario_DeleteBtn',
  Economics_ExistingWell_Btn = 'Economics_ExistingWell_Btn',
  Economics_CustomWell_Btn = 'Economics_CustomWell_Btn',
  Economics_Run_Scenario_Btn = 'Economics_Run_Scenario_Btn',
  Economics_Reset_Scenario_Btn = 'Economics_Reset_Scenario_Btn',
  Economics_SP_Estimate_Btn = 'Economics_SP_Estimate_Btn',
  Economics_Calculate_Btn = 'Economics_Calculate_Btn',
  Economics_Manual_Btn = 'Economics_Manual_Btn',

  Scenario_Delete_Dialog = 'Scenario_Delete_Dialog',
  Scenario_Share_Dialog = 'Scenario_Share_Dialog',
  Scenario_Import_Dialog = 'Scenario_Import_Dialog',

  ConfigPanel_Tab = 'ConfigPanel_Tab',
  Predict_ConfigPanel_Filter_Header = 'Predict_ConfigPanel_Filter_Header',
  Predict_ConfigPanel_Control_Header = 'Predict_ConfigPanel_Control_Header',
  Predict_ConfigPanel_Scenario_Header = 'Predict_ConfigPanel_Scenario_Header',
  Planner_ConfigPanel_Filter_Header = 'Planner_ConfigPanel_Filter_Header',
  Planner_ConfigPanel_Control_Header = 'Planner_ConfigPanel_Control_Header',
  Planner_ConfigPanel_Scenario_Header = 'Planner_ConfigPanel_Scenario_Header',
  Economics_ConfigPanel_Scenario_Header = 'Economics_ConfigPanel_Scenario_Header',
  Economics_ConfigPanel_Control_Header = 'Economics_ConfigPanel_Control_Header',
  Economics_Run_To_Update_Fields_Information = 'Economics_Run_To_Update_Fields_Information',
  Economics_Page_Shows_Current_Results = 'Economics_Page_Shows_Current_Results',
  Economics_ConfigPanel_Control_EconomicVariables_Header = 'Economics_ConfigPanel_Control_EconomicVariables_Header',
  Economics_ConfigPanel_EconomicVariables_CashTax = 'Economics_ConfigPanel_EconomicVariables_CashTax',
  Economics_ConfigPanel_EconomicVariables_CommodityPriceOil = 'Economics_ConfigPanel_EconomicVariables_CommodityPriceOil',
  Economics_ConfigPanel_EconomicVariables_FixedOpex = 'Economics_ConfigPanel_EconomicVariables_FixedOpex',
  Economics_ConfigPanel_EconomicVariables_CommodityPriceGas = 'Economics_ConfigPanel_EconomicVariables_CommodityPriceGas',
  Economics_ConfigPanel_EconomicVariables_InflationEscalationRate = 'Economics_ConfigPanel_EconomicVariables_InflationEscalationRate',
  Economics_ConfigPanel_EconomicVariables_Royalty = 'Economics_ConfigPanel_EconomicVariables_Royalty',
  Economics_ConfigPanel_EconomicVariables_SevAdValTaxRevenueGas = 'Economics_ConfigPanel_EconomicVariables_SevAdValTaxRevenueGas',
  Economics_ConfigPanel_EconomicVariables_SevAdValTaxRevenueOil = 'Economics_ConfigPanel_EconomicVariables_SevAdValTaxRevenueOil',
  Economics_ConfigPanel_EconomicVariables_TaxPerBblOil = 'Economics_ConfigPanel_EconomicVariables_TaxPerBblOil',
  Economics_ConfigPanel_EconomicVariables_TaxPerMcfGas = 'Economics_ConfigPanel_EconomicVariables_TaxPerMcfGas',
  Economics_ConfigPanel_EconomicVariables_VariableOpex = 'Economics_ConfigPanel_EconomicVariables_VariableOpex',
  Economics_ConfigPanel_EconomicVariables_SimulateWellShutoff = 'Economics_ConfigPanel_EconomicVariables_SimulateWellShutoff',
  Economics_ConfigPanel_Parameters = 'Economics_ConfigPanel_Parameters',
  Economics_ConfigPanel_EconomicVariables_Parameters_ExistingWell = 'Economics_ConfigPanel_EconomicVariables_Parameters_ExistingWell',
  Economics_ConfigPanel_EconomicsControl_WellDesignCapex_Calculate = 'Economics_ConfigPanel_EconomicsControl_WellDesignCapex_Calculate',
  Economics_ConfigPanel_Parameters_CustomWell = 'Economics_ConfigPanel_Parameters_CustomWell',
  Economics_ConfigPanel_Parameters_ExistingWell = 'Economics_ConfigPanel_Parameters_ExistingWell',
  Economics_ConfigPanel_Production_PredictiveModelSelection = 'Economics_ConfigPanel_Production_PredictiveModelSelection',
  WellDesignCapex_Calculate = 'Economics_ConfigPanel_EconomicsControl_WellDesignCapex_Calculate',
  WellDesignCapex_Calculate_LateralLength = 'WellDesignCapex_Calculate_LateralLength',
  WellDesignCapex_Calculate_TrueVerticalDepth = 'WellDesignCapex_Calculate_TrueVerticalDepth',
  WellDesignCapex_Calculate_ProppantVolume = 'WellDesignCapex_Calculate_ProppantVolume',
  WellDesignCapex_Calculate_WellOrientation = 'WellDesignCapex_Calculate_WellOrientation',
  WellDesignCapex_Calculate_WellVintageDate = 'WellDesignCapex_Calculate_WellVintage_Date',
  WellDesignCapex_Manual_ManualCapex = 'WellDesignCapex_Manual_ManualCapex',
  WellDesignCapex_SpEstimate_SpEstimateData = 'WellDesignCapex_SpEstimate_SpEstimateData',
  WellIdentification_WellIdentityField_WellName = 'WellIdentification_WellIdentityField_WellName',
  WellIdentification_WellIdentityField_WellNumber = 'WellIdentification_WellIdentityField_WellNumber',
  WellIdentification_WellIdentityField_Basin = 'WellIdentification_WellIdentityField_Basin',
  WellIdentification_WellIdentityField_PlayDesignation = 'WellIdentification_WellIdentityField_PlayDesignation',
  WellIdentification_WellIdentityField_Reservoir = 'WellIdentification_WellIdentityField_Reservoir',
  WellIdentification_WellIdentityField_StatusCurrentProd = 'WellIdentification_WellIdentityField_StatusCurrentProd',
  WellIdentification_WellIdentityField_DateVintage = 'WellIdentification_WellIdentityField_DateVintage',
  Economics_Scenario_SaveBtn = 'Economics_Scenario_SaveBtn',
  Economics_Scenario_DownloadBtn = 'Economics_Scenario_DownloadBtn',

  ConfigPanel_Accordion = 'ConfigPanel_Accordion',
  ConfigPanel_Filter = 'ConfigPanel_Filter',
  ConfigPanel_Control = 'ConfigPanel_Control',

  ConfigPanel_Applied_Filters_Panel = 'ConfigPanel_Applied_Filters_Panel',
  ConfigPanel_Applied_Filters_ClearBtn = 'ConfigPanel_Applied_Filters_ClearBtn',
  ConfigPanel_Applied_Filter = 'ConfigPanel_Applied_Filter',

  ConfigPanel_MapTooltip_Item = 'ConfigPanel_MapTooltip_Item',
  ConfigPanel_MapLayer_Item = 'ConfigPanel_MapLayer_Item',
  ConfigPanel_PredictionModel_Item = 'ConfigPanel_PredictionModel_Item',

  Impact_Landing_Page = 'Impact_Landing_Page',
  NoAccess_Msge = 'NoAccess_Msge',
  NoAccess_Contact_Msge = 'NoAccess_Contact_Msge',
  NoAccess_Or_Msge = 'NoAccess_Or_Msge',
  NoAccess_Phone = 'NoAccess_Phone',
  NoAccess_Email = 'NoAccess_Email',
  NoAccess_Error = 'NoAccess_Error',
  NoAccess_Expand_Summary = 'NoAccess_Expand_Summary',
  Main_Immerse_Frame = 'Main_Immerse_Frame',
  Landing_Panel_Title = 'Landing_Panel_Title',
  Landing_Panel_SubTitle = 'Landing_Panel_SubTitle',
  CloseButton = 'CloseButton',
  Dashboard_Tile = 'Dashboard_Tile',
  Dashboard_Tile_Tooltip = 'Dashboard_Tile_Tooltip',
  MarkAllRead = 'MarkAllRead',

  LandingPage_Download_US = 'LandingPage_Download_US',
  LandingPage_Download_CANADA = 'LandingPage_Download_CANADA',
  Landing_ViewAllScenarios = 'Landing_ViewAllScenarios',
  Landing_OpenNotificationsPanel = 'Landing_OpenNotificationsPanel',
  Landing_Notifications_Open = 'Landing_Notifications_Open',
  Landing_NewDashboard_Button = 'Landing_NewDashboard_Button',
  Landing_NewScenario_Button = 'Landing_NewScenario_Button',
  Landing_ScenariosList = 'Landing_ScenariosList',
  Landing_Notifications_Content = 'Landing_Notifications_Content',
  Landing_DashboardsLabel = 'Landing_DashboardsLabel',
  Landing_DashboardsList = 'Landing_DashboardsList',
  Landing_ViewAllDashboards = 'Landing_ViewAllDashboards',
  Landing_ScenariosLabel = 'Landing_ScenariosLabel',

  Notifications_AllNotifications = 'Notifications_AllNotifications',
  Notifications_DataUpdates = 'Notifications_DataUpdates',
  Notifications_PlatformUpdates = 'Notifications_PlatformUpdates',
  NotificationsPopUp = 'NotificationsPopUp',
  NotificationsPopUp_AllNotifications = 'NotificationsPopUp_AllNotifications',
  NotificationsPopUp_DataUpdates = 'NotificationsPopUp_DataUpdates',
  NotificationsPopUp_PlatformUpdates = 'NotificationsPopUp_PlatformUpdates',
  NotificationsPopUp_MarkAllRead = 'NotificationsPopUp_MarkAllRead',
  NotificationsPopUp_Close = 'NotificationsPopUp_Close',
  NotificationsPopUp_Content = 'NotificationsPopUp_Content',

  RoboTestClose = 'RoboTest_close',

  Economics_Scenario_Output_Box_Well_Information = 'Economics_Scenario_Output_Box_Well_Information',
  Economics_Scenario_Output_Box_Header_Well_Information = 'Economics_Scenario_Output_Box_Header_Well_Information',
  Economics_Scenario_Output_Box_Estimated_Capex = 'Economics_Scenario_Output_Box_Estimated_Capex',
  Economics_Scenario_Output_Box_Header_Estimated_Capex = 'Economics_Scenario_Output_Box_Header_Estimated_Capex',
  Economics_Scenario_Output_Box_Sum_Cashflow = 'Economics_Scenario_Output_Box_Sum_Cashflow',
  Economics_Scenario_Output_Box_Header_Sum_Cashflow = 'Economics_Scenario_Output_Box_Header_Sum_Cashflow',
  Economics_Scenario_Output_Box_Sum_FCF = 'Economics_Scenario_Output_Box_Sum_FCF',
  Economics_Scenario_Output_Box_Header_Sum_FCF = 'Economics_Scenario_Output_Box_Header_Sum_FCF',
  Economics_Scenario_Output_Box_NPV = 'Economics_Scenario_Output_Box_NPV',
  Economics_Scenario_Output_Box_Header_NPV = 'Economics_Scenario_Output_Box_Header_NPV',
  Economics_Scenario_Output_Box_BreakEven_Oil = 'Economics_Scenario_Output_Box_BreakEven_Oil',
  Economics_Scenario_Output_Box_Header_BreakEven_Oil = 'Economics_Scenario_Output_Box_Header_BreakEven_Oil',
  Economics_Scenario_Output_Box_BreakEven_Gas = 'Economics_Scenario_Output_Box_BreakEven_Gas',
  Economics_Scenario_Output_Box_Header_BreakEven_Gas = 'Economics_Scenario_Output_Box_Header_BreakEven_Gas',
  Economics_Scenario_Output_Box_IRR = 'Economics_Scenario_Output_Box_IRR',
  Economics_Scenario_Output_Box_Header_IRR = 'Economics_Scenario_Output_Box_Header_IRR',
  Economics_Scenario_Output_Box_RPV = 'Economics_Scenario_Output_Box_RPV',
  Economics_Scenario_Output_Box_Header_RPV = 'Economics_Scenario_Output_Box_Header_RPV',
  Economics_UWI_Insufficient_Data = 'Economics_UWI_InsufficientData',
  Economics_UWI_Unknown_Error = 'Economics_UWI_Unknown_Error',
  Economics_UWI_Prefilling_Error = 'Economics_UWI_Prefilling_Error',
}

export const PLATTS_CONNECT = 'plattsconnect';