import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';
import {
  ImpactCurvesOptionsRequestPayload,
  ImpactCurvesRequestPayload,
  PredictiveCurvesOptionsRequestPayload,
} from 'api/curves';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ImpactCurveData, ImpactCurvesState } from './types';

export const initialState: ImpactCurvesState = {
  status: RequestStatus.IDLE,
  error: null,
  data: null,
  options: {
    status: RequestStatus.IDLE,
    data: null,
  },
};

export const {
  actions,
  reducer,
  name: sliceKey,
} = createSlice({
  name: 'impactCurves',
  initialState,
  reducers: {
    loadImpactCurves(state, _action: PayloadAction<ImpactCurvesRequestPayload>) {
      state.status = RequestStatus.LOADING;
      state.error = null;
    },
    loadImpactCurvesOptions(state, _action: PayloadAction<ImpactCurvesOptionsRequestPayload>) {
      state.options.status = RequestStatus.LOADING;
      state.error = null;
    },
    loadPredictiveImpactCurvesOptions(state, _action: PayloadAction<PredictiveCurvesOptionsRequestPayload>) {
      state.options.status = RequestStatus.LOADING;
      state.error = null;
    },
    impactCurvesLoaded(state, { payload: data }: PayloadAction<ImpactCurveData>) {
      state.status = RequestStatus.SUCCESS;
      state.data = data;
    },
    impactCurvesOptionsLoaded(state, { payload: data }: PayloadAction<string[]>) {
      state.options.status = RequestStatus.SUCCESS;
      state.options.data = data;
    },
    impactCurvesError(state, action: PayloadAction<string>) {
      state.status = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    clearImpactCurvesError(state) {
      state.status = RequestStatus.IDLE;
      state.error = null;
    },
  },
});
