import { alpha } from '@material-ui/core/styles';

// Generated in https://www.npmjs.com/package/randomcolor
const defaultColorList = [
  'rgb(226, 165, 22)',
  'rgb(10, 84, 206)',
  'rgb(216, 62, 85)',
  'rgb(138, 69, 211)',
  'rgb(72, 234, 86)',
  'rgb(209, 242, 89)',
  'rgb(10, 56, 150)',
  'rgb(211, 61, 88)',
  'rgb(123, 96, 242)',
  'rgb(181, 30, 219)',
  'rgb(221, 157, 53)',
  'rgb(180, 34, 216)',
  'rgb(82, 192, 211)',
  'rgb(224, 71, 40)',
  'rgb(84, 111, 242)',
  'rgb(43, 181, 68)',
  'rgb(87, 97, 237)',
  'rgb(193, 9, 86)',
  'rgb(182, 201, 60)',
  'rgb(234, 103, 144)',
  'rgb(219, 50, 213)',
  'rgb(208, 52, 219)',
  'rgb(186, 141, 29)',
  'rgb(224, 185, 31)',
  'rgb(216, 32, 161)',
  'rgb(206, 43, 54)',
  'rgb(79, 121, 226)',
  'rgb(189, 93, 226)',
  'rgb(201, 62, 209)',
  'rgb(51, 173, 3)',
  'rgb(72, 38, 153)',
  'rgb(232, 48, 70)',
  'rgb(178, 68, 37)',
  'rgb(0, 113, 242)',
  'rgb(214, 29, 177)',
  'rgb(221, 88, 177)',
  'rgb(214, 40, 118)',
  'rgb(7, 161, 196)',
  'rgb(195, 239, 83)',
  'rgb(48, 93, 209)',
  'rgb(106, 237, 161)',
  'rgb(131, 219, 81)',
  'rgb(87, 224, 117)',
  'rgb(66, 209, 41)',
  'rgb(201, 32, 133)',
  'rgb(223, 105, 234)',
  'rgb(229, 207, 96)',
  'rgb(168, 232, 78)',
  'rgb(255, 25, 75)',
  'rgb(70, 252, 110)',
  'rgb(31, 112, 4)',
  'rgb(100, 186, 35)',
  'rgb(142, 48, 8)',
  'rgb(221, 99, 211)',
  'rgb(219, 13, 119)',
  'rgb(224, 51, 201)',
  'rgb(235, 252, 50)',
  'rgb(3, 122, 37)',
  'rgb(48, 181, 161)',
  'rgb(93, 219, 76)',
  'rgb(196, 13, 172)',
  'rgb(244, 101, 48)',
  'rgb(119, 165, 33)',
  'rgb(14, 97, 160)',
  'rgb(86, 252, 83)',
  'rgb(105, 63, 168)',
  'rgb(249, 51, 37)',
  'rgb(209, 156, 64)',
  'rgb(13, 173, 29)',
  'rgb(232, 20, 147)',
  'rgb(51, 14, 163)',
  'rgb(205, 58, 224)',
  'rgb(242, 82, 202)',
  'rgb(249, 150, 29)',
  'rgb(210, 11, 237)',
  'rgb(74, 152, 181)',
  'rgb(41, 83, 155)',
  'rgb(140, 31, 7)',
  'rgb(0, 252, 126)',
  'rgb(221, 95, 118)',
  'rgb(237, 14, 218)',
  'rgb(226, 94, 22)',
  'rgb(99, 232, 225)',
  'rgb(86, 124, 221)',
  'rgb(239, 86, 175)',
  'rgb(179, 216, 15)',
  'rgb(211, 115, 67)',
  'rgb(26, 191, 70)',
  'rgb(224, 35, 83)',
  'rgb(20, 89, 112)',
  'rgb(90, 118, 201)',
  'rgb(226, 247, 108)',
  'rgb(83, 209, 169)',
  'rgb(204, 85, 99)',
  'rgb(232, 234, 82)',
  'rgb(101, 214, 70)',
  'rgb(191, 219, 89)',
  'rgb(17, 49, 175)',
  'rgb(117, 224, 40)',
  'rgb(206, 166, 20)',
];

const defaultPaletteColorList = [
  'rgb(17, 95, 154)',
  'rgb(25, 132,197)',
  'rgb(34, 167, 240)',
  'rgb(72, 181, 196)',
  'rgb(118, 198, 143)',
  'rgb(166, 215, 91)',
  'rgb(201, 229, 47)',
  'rgb(208, 238, 17)',
  'rgb(208, 244, 0)',
];

const defaultColor = 'rgb(39, 174, 239)';
const nullValueColor = 'rgb(202, 202, 202)';

export const changeColorAlpha = (colors: string[], alphaValue: number) =>
  colors.map((color) => alpha(color, alphaValue));

export const getDefaultColor = (alpha: number) => changeColorAlpha([defaultColor], alpha)[0];
export const getNullValueColor = (alpha: number) => changeColorAlpha([nullValueColor], alpha)[0];

export const generateRGBAColors = (count: number, alphaValue: number, colorList: string[] = defaultColorList) => {
  return Array.from({ length: count }, (_element, index) => alpha(colorList[index % colorList.length], alphaValue));
};

export const generateRGBAColorPalette = (alpha: number, paletteColorList: string[] = defaultPaletteColorList) => {
  return changeColorAlpha(paletteColorList, alpha);
};

const getRGBAValues = (color: string) => {
  const match = color.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);

  return match ? [Number(match[1]), Number(match[2]), Number(match[3])] : [];
};
export const generateRGBAColorShades = (size: number, color: string, alphaValue: number) => {
  const colorList = [];
  let step = size <= 10 ? 10 : 100;
  const devider = size <= 10 ? 10 : 100;
  const colors = getRGBAValues(color);
  for (let i = 0; i < size; i++) {
    colorList[i] = `rgb(${Math.round((colors[0] * step) / devider)}, ${Math.round(
      (colors[1] * step) / devider,
    )}, ${Math.round((colors[2] * step) / devider)})`;
    step--;
  }

  return generateRGBAColors(size, alphaValue, colorList);
};
export const generateRangeColorPalette = (count: number, colors: string[] | undefined, alphaValue: number) => {
  if (!colors || !colors.length) {
    return defaultColorList;
  }
  const size = Math.round(count / colors.length);

  return colors.reduce(
    (prev: string[], curr: string) => [...prev, ...generateRGBAColorShades(size, curr, alphaValue)],
    [],
  );
};
